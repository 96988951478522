import React, { FC } from 'react';
import { ReactComponent as Logo } from '../../images/internetstiftelsen_logo.svg';

const TopNav: FC = () => {

  return (
    <header className="o-header">
      <div className="wrapper">
        <div className="row justify-content-between align-items-center flex-nowrap">
          <div className="grid-auto">
            <a href="/" className="o-header__logo-link">
              <span className="o-header__logo">
                <Logo className="logotype" />
              </span>
              <span className="u-visuallyhidden">To start</span>
            </a>
          </div>
          <div className="grid"></div>
        </div>
      </div>
    </header>
  );
};

export default TopNav;
