import { Result, Ok, Err } from './Result';

export const getEnvironmentVariable = (name: string): Result<string, string> => {
  const metaTagElem = document.querySelector(`meta[name="${name}"]`);
  const metaTagElemContent = metaTagElem ? metaTagElem.getAttribute('content') : null;
  return metaTagElemContent ? new Ok(metaTagElemContent) : new Err('');
}

export const getApiURL = (): string => {
  return getEnvironmentVariable('env-api-url').match({
    'Ok': (ok) => ok.value,
    'Err': (_) => {
      const envApiUrl = process.env.REACT_APP_API_URL;
      if (typeof envApiUrl !== 'undefined') return envApiUrl;
      return '';
    }
  });
};
