import React, { FC } from 'react';

interface ICardProps {
  children: React.ReactNode;
  className?: string;
  component?: 'div' | 'article';
  aboveContent?: React.ReactNode;
}

export const Card: FC<ICardProps> = (props: ICardProps) => {
  const { children, className, component, aboveContent } = props;
  const cardClasses = `m-card m-card--padded ${className ? className : ''}`;

  const getCardContent = (
    <>
      {aboveContent}
      <div className="m-card__content">
        {children}
      </div>
    </>
  );

  if (component === 'article') {
    return (
      <article className={cardClasses}>
        {getCardContent}
      </article>
    );
  }

  return (
   <div className={cardClasses} style={{overflow: "visible"}}>
     {getCardContent}
   </div>
  );
};

export default Card;
