interface IMatch<T,E,U,V> {
    Ok: (res: Ok<T,E>) => U;
    Err: (res: Err<T,E>) => V;
}

export abstract class Result<T, E> {
    abstract readonly __type: string
    
    /**
     * isOk checks if the Result is of type `Ok<T,E=never>`. I.e if 
     * there is an result.
     * 
     * This function also functions as a type guard.
     */
    public isOk(): this is Ok<T,E> {
        if (this.__type === 'Ok') return true
        return false
    }

    /**
     * isErr check if the result is of type `Err<T,E>`. I.e if there
     * is an error.
     * 
     * This function also functions as a type guard.
     */
    public isErr(): this is Err<T,E> {
        return !this.isOk()
    }

    /**
     * map applies a function to the Ok value if there is one else it will
     * return the an Err type. This allows chaining without having to worry
     * about an error.
     * 
     * Example: 
     *    let a = new Ok(10)
     *    a.map((x) => x * x).map((x) => x * 2) // => Ok(200)
     *    let c = new Err("Error")
     *    c.map((x) => x * x).map((x) => x * 2) // => Err("Error")
     * 
     * @param fn function to apply if the result is of type `Ok<T,E=never>`
     */
    abstract map<U>(fn: (v: T) => U): Result<U,E>

    /**
     * Like `map` but will return the Ok value if there is on else return the 
     * supplied default value.
     * 
     * @param def Default value if no Ok value is found
     * @param f Function to apply to Ok value
     */
    mapOr<U>(def: U, f: (v: T) => U): U {
        if (this.isOk()) return f(this.value)

        return def;
    }

    /**
     * Unpack a `Result<T,E>` to `U`. This function is used to unpack an Ok value
     * but also handle errors.
     * 
     * @param def Function to apply if there is an error value contained
     * @param fn Function to apply if there is an Ok value contained
     */
    abstract mapOrElse<U>(def: (err: E) => U, fn: (val: T) => U): U

    /**
     * Map the result to `Result<T,F>`. This allows you to pass through an Ok value
     * but handle any erros.
     * 
     * @param fn Function to apply to any error value found
     */
    abstract mapErr<F>(fn: (err: E) => F): Result<T,F>

    /**
     * Unwraps the contained Ok value. Throws an exception if there is an error.
     */
    abstract unwrap(): T

    /**
     * Unwraps the contained Ok value. Returns `def` if there is an error.
     * 
     * @param def Default value to return on error
     */
    unwrapOr(def: T): T {
        if (this.isOk()) {
            return this.value
        }

        return def
    }


    /**
     * Return the contained Ok value or compute it from the error.
     * 
     * @param fn Function to apply to an error value.
     */
    abstract unwrapOrElse(fn: (err: E) => T): T

    /**
     * match takes an object of type IMatch which are two
     * functions to handle both an Ok(value) and Err(error) result.
     * 
     * Example:
     * 
     *     function divide(n: number, divisor: number): Result<number, Error> {
     *         if (divisor === 0) return new Err("Divide by zero error")
     *         return Ok(n / divisor)
     *     }
     * 
     *     let num = divide(10, 2);
     *     num.match({
     *         Ok: (res) => console.log(res.value)
     *         Err: (error) => console.log(error.err)
     *     })
     */
    abstract match<U,V>(matcher: IMatch<T,E,U,V>): U | V

}

export class Ok<T,E = never> extends Result<T,E> {
    readonly __type = 'Ok'
    readonly value: T

    constructor(value: T) {
        super()
        this.value = value
    }

    map<U>(fn: (v: T) => U): Result<U,E> {
        return new Ok(fn(this.value))
    }

    mapOrElse<U>(_: (err: E) => U, fn: (val: T) => U): U { 
        return fn(this.value)
    }

    mapErr<F>(_: (err: E) => F): Result<T,F> {
        return new Ok(this.value)
    }

    unwrap(): T {
        return this.value
    }

    unwrapOrElse(_: (err: E) => T): T {        
        return this.value
    }

    match<U,V>(matcher: IMatch<T,E,U,V>): U {
        return matcher.Ok(this)
    }
}

export class Err<T,E> extends Result<T,E> {
    readonly __type = 'Err'
    readonly err: E

    constructor(err: E) {
        super()
        this.err = err
    }

    map<U>(_: (v: T) => U): Result<U,E> {
        return new Err<U,E>(this.err)
    }
    
    mapOrElse<U>(def: (err: E) => U, _: (val: T) => U): U {
        return def(this.err)
    }

    mapErr<F>(fn: (err: E) => F): Result<T,F> {
        return new Err(fn(this.err))
    }

    unwrap(): T {
        throw new Error(`Called unwrap on error result: ${this.err}`)
    }


    unwrapOrElse(fn: (err: E) => T): T {
        return fn(this.err)
    }

    
    match<U,V>(matcher: IMatch<T,E,U,V>): V {
        return matcher.Err(this)
    }
}