import React, { FC } from 'react';

interface ISpinnerProps {
    className: string;
}

export const Spinner: FC<ISpinnerProps> = (props: ISpinnerProps) => {
    const { className } = props;

    return (
        <figure className={`spinner ${className}`}>
            <svg width="100%"
                 height="100%"
                 xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 100 100"
                 preserveAspectRatio="xMidYMid" >
                     <circle cx="50"
                             cy="50"
                             fill="none"
                             r="43"
                             stroke="#50b2fc"
                             strokeWidth="7">
                    </circle>
                    <circle cx="50"
                            cy="50"
                            fill="none"
                            r="43"
                            stroke="#a7d8fd"
                            strokeWidth="7"
                            strokeLinecap="square"
                            transform="rotate(27.6965 50 50)">
                                <animateTransform attributeName="transform"
                                                  type="rotate"
                                                  calcMode="linear"
                                                  values="0 50 50;180 50 50;720 50 50"
                                                  keyTimes="0;0.5;1"
                                                  dur="2.5s"
                                                  begin="0s"
                                                  repeatCount="indefinite">
                                </animateTransform>
                                <animate attributeName="stroke-dasharray"
                                         calcMode="linear"
                                         // eslint-disable-next-line max-len
                                         values="9.42477796076938 179.0707812546182;188.4955592153876 -2.842170943040401e-14;9.42477796076938 179.0707812546182"
                                         keyTimes="0;0.5;1"
                                         dur="2.5"
                                         begin="0s"
                                         repeatCount="indefinite">
                                </animate>
                    </circle>
            </svg>
        </figure>
    )
}

export default Spinner;
