/* eslint-disable max-len */
const translations = {
  "Footer text": " is an independent, business-driven and public-benefit organization. We work for an internet that contributes positively to people and society. We are responsible for the internet's Swedish top-level domain .se and manages the operation and administration of the top-level domain .nu. The revenue from our business finances a number of initiatives in order to enable people to use the internet in the best way, and to stimulate the sharing of knowledge and innovation with a focus on the internet.",
  "Certification text": "Certified in accordance to ISO/IEC 27001:2013",
  "Postal address stiftelsen": "The Swedish Internet Foundation\nBox 92073\n120 07 Stockholm\nSweden",
  "Online address stiftelsen": "E-mail: registry@internetstiftelsen.se\nOrganisation number: 802405-0190",
  "footer_site1_h": "The Swedish Internet Foundation",
  "footer_site2_h": "The Internet Days",
  "footer_site3_h": "Goto 10",
  "footer_site4_h": "Bredbandskollen",
  "footer_site5_h": "Internetmuseum",
  "footer_site6_h": "Digital lessons",
  "footer_site1_desc": "Is an independent, business-driven and public-benefit organization.",
  "footer_site2_desc": "A conference organized by The Swedish Internet Foundation.",
  "footer_site3_desc": "Open space for internet related knowledge exchange and innovation, run by the Swedish Internet Foundation.",
  "footer_site4_desc": "Independent measuring tool run by The Swedish Internet Foundation.",
  "footer_site5_desc": "Internetmusem is a digital museum run by The Swedish Internet Foundation.",
  "footer_site6_desc": "Digital lessons is a free collection of lessons and material covering areas concerning digital competency and programming suitable for elementary school.",
} as {[key: string]: string}
/* eslint-enable max-len */

export const getTranslatedText = (text: string): string => {
  const t = translations[text];
  if (t) {
    return t;
  }

  return text;
};

// Status texts from API are in snake format.
export const readableStatusText = (label: string): string => {
  /* eslint-disable max-len */
  switch (label.toLowerCase()) {
    case 'awaiting_grace_period':
      return 'Awaiting grace period = domain will be checked from two regions during a period of 72 hours.'
    case 'awaiting_more_regions':
      return 'Awaiting more regions = domain checked from one region. Needs to be checked from one more region with the same result.'
    case 'done':
        return 'Done = The scanning process has completed successfully.'
    case 'error':
      return 'Error = During the scanning process we encountered an error when querying the DNS servers from different regions.'
    case 'not_checked':
    case 'no_records_found':
      return 'No records found = domain has been scanned but no cds/csync records found.'
    case 'updating_zone':
      return 'Updating zone = Propagating changes to the registry database.'
    default:
      return label;
  }
  /* eslint-enable max-len */
}
